import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/layout'
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../components/SEO"

const Contact = (props) => {
  //console.log("props:", props)
  const { data } = props;
  const {
    title,
    summary: { summary },
    mainText,
    mainImage,
    supplText,
    supplImage,
  } = data.toppage
  const { assetImg } = data.asset

  const Bold = ({ children }) => <strong>{children}</strong>
  const Text = ({ children }) => <p>{children}</p>
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        if (node.data.target.fields) {
          let url = node.data.target.fields.file['en-US'].url;
          return <img className="textImg" src={'https:' + url} />
        }
      }
    },
  }

  return (
    <Layout>
      <SEO title={title} description={summary} />
      <>
        <div>
          <h1>{title}</h1>
        </div>
        <div>
          {mainImage ?
            <Image fluid={mainImage.fluid} className="mainImg" alt="" />
            : null
          }
          {documentToReactComponents(mainText.json, options)}
          {supplText ?
            <div className="suppl">
              <div>{documentToReactComponents(supplText.json, options)}</div>
              <div>{supplImage ?
                <Image fluid={supplImage.fluid} alt="" />
                : ''
              }</div>
            </div>
            : null
          }
          {assetImg ?
            <div className="fixed">
              <Image fluid={assetImg.fluid} alt="" />
            </div>
            : ''
          }
        </div>
        <div className="clear" />
      </>
    </Layout>
  )
}

export const query = graphql`
  query {
    toppage: contentfulTopPage(slug: { eq: "contact" }) {
      title
      summary {
        summary
      }
      mainText {
        json
      }
      mainImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }

    asset: contentfulAsset(name: {eq: "email"}) {
      assetImg: image {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }

  }
`

export default Contact
